<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card>
      <!-- #0d6efd -->
      <h3>
        {{ "Reservation Surgery Details For " }}
        <label style="color: #0d6efd">{{ Model.SurgeryName }}</label>
        {{ "(Under Specialty" }}
        <label style="color: rgb(30, 20, 79)"> {{ Model.SpecialtyName }}</label>
        {{ ")" }}
      </h3>
      <div class="vx-row">
        <!-- Reference Number -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Reference Number") }}</label>
          <vs-input
            disabled
            v-model="Model.ReferenceNumber"
            class="w-full"
            name="Reference Number"
            :data-vv-as="$t('Reference Number')"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Reference Number')"
            >{{ errors.first("Reference Number") }}</span
          >
        </div>
        <!-- Patient Name -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Name") }}</label>
          <vs-input
            disabled
            v-model="Model.PatientName"
            class="w-full"
            name="Patient Name"
            :data-vv-as="$t('Patient Name')"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Patient Name')"
            >{{ errors.first("Patient Name") }}</span
          >
        </div>
        <!-- Patient Email -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Email") }}</label>
          <vs-input
            disabled
            v-model="Model.PatientEmail"
            class="w-full"
            :data-vv-as="$t('Patient Email')"
            name="Patient Email"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Patient Email')"
            >{{ errors.first("Patient Email") }}</span
          >
        </div>
        <!-- Patient Phone -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Phone") }}</label>
          <vs-input
            disabled
            v-model="Model.PatientPhone"
            class="w-full"
            name="Patient Phone"
            v-validate="'required'"
          />
          <span
            class="text-danger md:w-1/3 text-sm"
            v-show="errors.has('Patient Phone')"
            >{{ errors.first("Patient Phone") }}</span
          >
        </div>

        <!--Set Doctor -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Set Doctor") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="Model.DoctorID"
            :options="doctorList"
            :reduce="(ID) => ID.ID"
            :disabled="!Editable"
          />
          <span class="text-danger text-sm" v-show="errors.has('Doctor')">{{
            errors.first("Doctor")
          }}</span>
        </div>
  
        <!--Set Surgery Date -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Set Surgery Date") }}</label>
          <datepicker
            :disabled="!Editable"
            v-model="Model.SurgeryDate"
            class="vx-col w-full"
            name="Date"
          ></datepicker>
        </div>
        <!-- Surgery Date To -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Surgery Date To") }}</label>
          <datepicker
            v-model="Model.SurgeryDateTo"
            class="vx-col w-full"
            name="Date"
            disabled
          ></datepicker>
        </div>
        <!-- Surgery Date From -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("Surgery Date From")
          }}</label>
          <datepicker
            v-model="Model.SurgeryDateFrom"
            class="vx-col w-full"
            name="Date"
            disabled
          ></datepicker>
        </div>
        <!-- Status -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="Model.StatusID"
            :options="nextStatus"
            :reduce="(ID) => ID.ID"
            :disabled="!Editable"
          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>
        <!-- Payment Type -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Payment Type") }}</label>

          <v-select
            label="Name"
            class="w-full"
            :disabled="true"
            v-model="Model.PaymentTypeID"
            :options="PaymentTypes"
            :reduce="(ID) => ID.ID"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Payment Type')"
            >{{ errors.first("Payment Type") }}</span
          >
        </div>
        <!-- Payment Status -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>

          <v-select
            label="Name"
            class="w-full"
            :disabled="!Editable"
            v-model="Model.PaymentStatusID"
            :options="PaymentStatus"
            @input="FilterStatus"
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>

        <!-- Processing Fees -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("Processing Fees USD")
          }}</label>
          <vs-input
            disabled
            v-model="Model.ProcessingFees"
            class="w-full"
            name="ProcessingFees"
            :data-vv-as="$t('ProcessingFees')"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('ProcessingFees')"
            >{{ errors.first("ProcessingFees") }}</span
          >
        </div>
        <!-- VAT Fees -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("VAT USD") }}</label>
          <vs-input
            disabled
            v-model="Model.VAT"
            class="w-full"
            name="VAT"
            :data-vv-as="$t('VAT')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('VAT')">{{
            errors.first("VAT")
          }}</span>
        </div>
        <!-- Price -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Price") }}</label>
          <vs-input
            v-model="Model.Price"
            class="w-full"
            name="Price"
            :data-vv-as="$t('Price')"
            v-validate="'required'"
            :disabled="!Editable"
          />
          <span class="text-danger text-sm" v-show="errors.has('Price')">{{
            errors.first("Price")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="Model.StatusID == 3">
          <label class="text-sm opacity-75">{{
            $t("Cancellation Reasons")
          }}</label>
          <vs-textarea
            v-model="Model.CancellationReasons"
            class="w-full"
            disabled
            name="CancellationReasons"
            :data-vv-as="$t('PrCancellationReasonsice')"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('CancellationReasons')"
            >{{ errors.first("CancellationReasons") }}</span
          >
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col lg:w-1/3">
          <vx-card class="flex-1">
            <div>
              <h5 style="color: red">
                <p>{{ $t("Surgery Treatment Includes") }}</p>
              </h5>
            </div>
            <div v-for="item in Model.SurgeryIncludedItems" :key="item.ID">
              <p class="circle primaryBackColor mt-1 mr-2"></p>
              <h5>
                <p>{{ item.NameEN }}</p>
              </h5>
            </div>

            <div>
              <h5 style="color: red">
                <p>{{ $t("Surgery Treatment Not Includes") }}</p>
              </h5>
            </div>

            <div
              class=""
              v-for="(item, indext) in Model.SurgeryExcludedItems"
              :key="indext"
            >
              <p class="circle primaryBackColor mt-1 mr-2"></p>
              <h5>
                <p>{{ item.NameEN }}</p>
              </h5>
            </div>
          </vx-card>
        </div>

        <div class="lg:w-2/3" id="data-list-list-view">
          <vx-card class="flex-1" title="Extra Services">
            <div class="vx-row">
              <p
                style="font-size: 1.2rem"
                class="mt-2"
                v-if="Model.ExtraServiceTotalPrice"
              >
                {{ $t("Total Price") }} : {{ Model.ExtraServiceTotalPrice }} USD
              </p>
              <p
                style="font-size: 1.2rem"
                class="mt-2 ml-5"
                v-if="Model.ExtraServiceUnpayedPrice"
              >
                {{ $t("Total Unpayed") }} :
                {{ Model.ExtraServiceUnpayedPrice }} USD
              </p>
            </div>

            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="Model.PatientReservationSurgeryExtraServices"
            >
              <template slot="thead">
                <vs-th> {{ $t("Service Name") }}</vs-th>
                <vs-th> {{ $t("Count ") }}</vs-th>
                <vs-th> {{ $t("Total Price") }}</vs-th>
                <vs-th> {{ $t("Payment status") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.ExtraService.NameEN }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                         {{ tr.Count }} 
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                         {{ tr.Price }} USD 
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate" v-if="tr.ExtraService.isPayment">
                          <!-- {{ tr.PaymentStatus.NameEN }}  -->
                          {{$t("Yes")}}
                      </p>
                      <p class="product-name font-medium truncate" v-else>
                          <!-- {{ tr.PaymentStatus.NameEN }}  -->
                          {{$t("No")}}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col lg:w-1/3">
          <vx-card class="mb-base">
            <h3 class="mb-4">Surgery Logs</h3>
            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="Model.Patientlogs"
            >
              <template slot="thead">
                <vs-th> {{ $t("Details") }}</vs-th>
                <vs-th> {{ $t("Date") }}</vs-th>
                <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr1"
                    :key="indexkr"
                    v-for="(tr1, indexkr) in data"
                  >
                    <vs-td>
                      <p class="product-name font-medium">
                        {{ tr1.Details }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr1.CreatedDate | formatDate() }}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>

        <div class="lg:w-2/3" id="data-list-list-view">
          <vx-card class="flex-1" title="Surgery Comments">
            <div class="inline-flex">
              <vs-input
                :label="$t('Comment')"
                v-model="Comment"
                style="width: 40rem !important"
                class="mb-5"
                name="Comment"
                v-validate="'required'"
              />
              <vs-button
                type="border"
                
                class="ml-5 mt-6"
                @click="addComment"
                >{{ $t("add Comment") }}</vs-button
              >
            </div>
            <vs-table
              ref="table2"
              pagination
              :max-items="5"
              :data="Model.PatientReservationSurgeryComments"
            >
              <template slot="thead">
                <vs-th>{{ $t("Comment") }} </vs-th>
                <vs-th> {{ $t("Person") }}</vs-th>
                <vs-th> {{ $t("Date") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="td" :key="indexr" v-for="(td, indexr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.Comment }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.User.FullName }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ td.CreatedDate | formatDate() }}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>

      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="ml-4 mt-2"
              @click="save_changes"
              :disabled="!isFormValid"
            >
              {{ $t("Save") }}
            </vs-button>
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="danger"
              @click="Back()"
            >
              {{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSurgery/moduleReservation.js";
import vSelect from "vue-select";
// import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import modulePaymentType from "@/store/settings/paymentType/modulePaymentType.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      paymentStatus: [],
      Status: [],
      FilterdStatus: [],
      Model: {},
      doctorHospitalSpecialty: [],
      doctorList: [],
      Comment: "",
      // uploadUrl: domain + "API/Course/UploadFile",
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // baseURL: domain,
    };
  },
  watch: {
    StatusID() {
      var vm = this;
      var status = this.ALLstatus.filter((obj) => obj.ID == vm.Model.StatusID);
      var Payments = status[0].RelatedPaymentStatus;
      this.paymentStatus =
        this.$store.state.PaymentStatusList.PaymentStatus.filter((obj) =>
          Payments.includes(obj.ID)
        );
    },
  },
  computed: {
    Editable() {
      return this.Model.MainStatusID != 5 && this.Model.MainStatusID != 3;
    },
    isFormValid() {
      debugger;
      var vm = this;
      var index = this.PaymentStatus.findIndex(
        (obj) => obj.ID == vm.Model.PaymentStatusID
      );
      return !this.errors.any() && this.Model.StatusID != null && index >= 0;
    },
    MainStatusProcess() {
      var s = this.Model.MainStatusID;
      var status = this.ALLstatus.filter((obj) => obj.ID == s);
      return status[0].OrderProcessBy;
    },
    PaymentStatus() {
      return this.paymentStatus;
    },
    StatusID() {
      return this.Model.StatusID;
    },
    ALLstatus() {
      return this.Status;
    },
    nextStatus() {
      debugger;
      var vm = this;
      return this.ALLstatus.filter(
        (obj) =>
          obj.OrderProcessBy >= vm.MainStatusProcess || !obj.OrderProcessBy
      );
    },
    PaymentTypes() {
      return this.$store.state.PaymentTypeList.PaymentTypes;
    },
  },
  methods: {
    GetAllStatuses() {
      debugger;
      this.$store
        .dispatch("ReservationSurgeryList/GetAllStatuses")
        .then((res) => {
          this.Status = res.data.Data;
          this.FilterdStatus = res.data.Data;
          this.FilterStatus(true);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    addComment() {
      const obj = {
        ID: this.ID,
        Comment: this.Comment,
        UserID: this.$store.state.AppActiveUser.uid,
        PatientReservationSurgeryID: this.$route.params.ID,
      };

      this.$store
        .dispatch(
          "ReservationSurgeryList/AddPatientReservationSurgeryComment",
          obj
        )
        .then((res) => {
          debugger;

          this.Model.PatientReservationSurgeryComments.push(res.data.Data);
        })
        .catch((err) => {
          console.error(err);
          window.showError(err.response.data.message);
        });
    },
    Back() {
      //a7ot el parameter
      this.$router.go(-1);
    },
    save_changes() {
      this.$store
        .dispatch(
          "ReservationSurgeryList/UpdatePatientReservationSurgery",
          this.Model
        )
        .then(() => {
          window.showAddSuccess();
          this.Back();

          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            window.showAddFailed(err.response.data.message);
          } else {
            window.showAddFailed("");
          }
          //console.error(err);
          this.$vs.loading.close();
        });
    },

    getDoctorHospitalSpecialty() {
      debugger;
      var SurgeryObj;
      this.$store
        .dispatch("patientList/GetSurgery", this.Model.SurgeryID)
        .then((res) => {
          debugger;
          SurgeryObj = res.data.Data;

       
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      debugger;
    },
    FilterStatus(isCreate) {
      if (isCreate != true) {
        this.Model.StatusID = null;
      }
      if (this.Model.PaymentStatusID == 1) {
        // this.FilterdStatus =  this.Status.filter(x=>x.ID!3);
      }

      if (this.Model.PaymentStatusID < 3) {
        this.FilterdStatus = this.Status.filter((x) => x.ID <= 3);
      }

      if (this.Model.PaymentStatusID == 3) {
        this.FilterdStatus = this.Status.filter((x) => x.ID == 3);
      }
    },
    getDoctorList() {
      debugger;
      var doctorModel = {};
      doctorModel.HospitalID = this.Model.HospitalID;
      
     doctorModel.HospitalSurgeryID=this.Model.HospitalSurgeryID;
      this.$vs.loading();
      this.$store
        .dispatch("HospitalList/GetHospitalSurgeryDoctors", doctorModel)
        .then((res) => {
          debugger;
          this.doctorHospitalSpecialty = res.data.Data;
          for (
            var counter = 0;
            counter < this.doctorHospitalSpecialty.length;
            counter++
          ) {
            this.doctorList.push(this.doctorHospitalSpecialty[counter].Doctor);
          }

          debugger;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!modulePaymentType.isRegistered) {
      this.$store.registerModule("PaymentTypeList", modulePaymentType);
      modulePaymentType.isRegistered = true;
    }

    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }
    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationSurgeryList", moduleReservation);
      moduleReservation.isRegistered = true;
    }

    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();
      this.$store
        .dispatch("ReservationSurgeryList/GetPatientReservationSurgery", ID)
        .then((res) => {
          debugger;
          //mkl
          this.Model = res.data.Data;
          debugger;
          this.$set(this.Model, "MainStatusID", this.Model.StatusID);
          debugger;
          this.getDoctorHospitalSpecialty();
          this.FilterStatus(true);
          this.getDoctorList();
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
        });
    }
    this.GetAllStatuses();
    this.$store.dispatch("PaymentTypeList/GetAllPaymentTypes");

    this.FilterStatus(true);
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
