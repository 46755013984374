<template>
  <div>
   <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.go(-1)"
    />
    <vs-alert color="danger" title="Course Not Found" :active.sync="Hospital_not_found">
      <span>Hospital record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'HospitalSurgeries' }" class="text-inherit underline"
          >All Hospital</router-link
        >
      </span>
    </vs-alert>
    <div>
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <!-- MainInfo -->
            <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <reservation-surgery-details class="mt-4" :HospitalSurgeryModel="Model" />
              </div>
            </vs-tab>
            <!-- Scans -->
            <vs-tab
              :label="$t('Medical History Scans')"
              icon-pack="feather"
              icon="icon-share-2"
            >
              <div class="tab-text">
                <add-edit-MedicalHistoryScans
                  class="mt-4"
                  :DocumentModel="DocumentModel"
                />
              </div>
            </vs-tab>
            <!-- Reports -->
            <vs-tab
              :label="$t('Medical History Reports')"
              icon-pack="feather"
              icon="icon-share-2"
            >
              <div class="tab-text">
                <add-edit-medicalHistoryReports
                  class="mt-4"
                  :DocumentModel="DocumentModel"
                />
              </div>
            </vs-tab>
            <!-- Analysis -->
            <vs-tab
              :label="$t('Medical History Analysis')"
              icon-pack="feather"
              icon="icon-share-2"
            >
              <div class="tab-text">
                <add-edit-medicalHistoryAnalysis
                  class="mt-4"
                  :DocumentModel="DocumentModel"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReservationSurgeryDetails from "./ReservationSurgery-Details.vue";
import moduleReservation from "@/store/reservation/ReservationSurgery/moduleReservation.js";
import AddEditMedicalHistoryScans from "./ReservationSurgeryScanTabMain.vue";
import AddEditMedicalHistoryReports from "./ReservationSurgeryReportTabMain.vue";
import AddEditMedicalHistoryAnalysis from "./ReservationSurgeryAnalysisTabMain.vue";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moduleReservationSurgery from "@/store/reservation/ReservationSurgery/moduleReservation.js";


export default {
  components: {
    ReservationSurgeryDetails,
    AddEditMedicalHistoryReports,
    AddEditMedicalHistoryScans,
    AddEditMedicalHistoryAnalysis,

    vSelect,
    Datepicker,
  },
  data() {
    return {
      Hospital_not_found: false,
      activeTab: 0,
      Model: {},
      DocumentModel: {},
    };
  },

  computed: {},
  methods: {},

  created() {
        debugger;
    if (!moduleReservationSurgery.isRegistered) {
      debugger;
      this.$store.registerModule("ReservationSurgeryList", moduleReservationSurgery);
      moduleReservationSurgery.isRegistered = true;
    }
    debugger;
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();
      debugger;

      this.$store
        .dispatch("ReservationSurgeryList/GetPatientReservationSurgeryDocuments", ID)
        .then((res) => {
          debugger;
          this.DocumentModel = res.data.Data;
          debugger;
          //this.$set(this.Model, "MainStatusID", this.Model.StatusID);
          debugger;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
        });
    }
    // this.FilterStatus(true);

  },
};
</script>
